import React from "react";
import { RegisterContext } from "./context";
import { withFirebase , onAuthStateChanged} from "../firebase";
import withCourse from "../course/course";
import { getProperty } from "../util";
import { getUserEmailDomain } from "../user";

const withRegistration = (Component) => {
  class WithRegistration extends React.Component {
    constructor(props) {
      super(props);

      /*
       * registration:
       * - false: ei tiedossa ja ladataan tai ei vaadita (settings.registration.enable tai settings.permissions.disableVoucher)
       * - null: ei kirjautunut
       * - Object: kirjautunut
       */
      this.state = {
        registration: false,
      };
    }

    componentDidMount = () => {
      onAuthStateChanged(this.props.firebase.auth ,authUser => {
        if (authUser) {
          // Logged in
          this.checkRegistration(authUser);
        } else {
          // Not logged in
          this.setState({ registration: null });
        }
      });
    };

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.course !== this.props.course) {
        this.props.firebase.stopWatchingAnswers(prevProps.course.id, this.watchregistrationCb);
        this.checkRegistration();
      }
    }

    componentWillUnmount() {
      const courseId = getProperty("course.id", this.props);
      this.props.firebase.stopWatchingAnswers(courseId, this.watchregistrationCb);
    }

    watchregistrationCb = (courseData)=> {
      if (courseData) {
        // Might be registered
        this.setState({
          registration: {
            registration_time: courseData.registration_time,
            registration_type: courseData.registration_type,
            registration_later_time: courseData.registration_later_time,
            registration_check_time: courseData.registration_check_time,
          },
        });
      } else {
        // No course data -> not registered
        this.setState({ registration: null });
      }
    }

    checkRegistration = (authUser) => {
      const courseId = getProperty("course.id", this.props);
      if (!courseId) {
        this.setState({ registration: false });
        return;
      }

      const enableRegistration = getProperty("settings.registration.enable", this.props.course) === true;
      if (!enableRegistration) {
        this.setState({ registration: false });
        return;
      }

      const userDomain = getUserEmailDomain(authUser);
      if (getProperty("settings.permissions.disablevoucher.domains", this.props.course, []).includes(userDomain)) {
        this.setState({ registration: false });
        return;
      }

      this.props.firebase.watchUserCourse(courseId, this.watchregistrationCb);
    };

    render() {
      return (
        <RegisterContext.Provider value={this.state.registration}>
          <Component {...this.props} />
        </RegisterContext.Provider>
      );
    }
  }
  return withFirebase(withCourse(WithRegistration));
};
export default withRegistration;
