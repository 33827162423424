import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function UnauthenticatedRoute({
  component: Comp,
  appProps,
  componentProps,
  checkAuth,
  checkAuthPW,
  checkVoucher,
  coursePassword,
  courseName,
  ...rest
}) {
  const redirect = querystring("redirect");

  if (checkAuth && !appProps.isAuthenticated) {
    return <Route {...rest} render={(props) => <Comp {...props} {...componentProps} appProps={appProps} />} />;
  }
  if (checkAuthPW && !appProps.isAuthenticatedPW) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Comp
            {...props}
            {...componentProps}
            appProps={appProps}
            coursePassword={coursePassword}
            courseName={courseName}
          />
        )}
      />
    );
  }
  if (checkVoucher && !appProps.hasVoucher) {
    return <Route {...rest} render={(props) => <Comp {...props} {...componentProps} appProps={appProps} />} />;
  }

  return (
    <Route {...rest} render={(props) => <Redirect to={redirect === "" || redirect === null ? "/" : redirect} />} />
  );
}

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
