import React, { useCallback, useContext, useEffect, useState } from "react";
import { CourseContext } from "../util/course";
import { FirebaseContext } from "../util/firebase";
import { QuickSettingsContext } from "../util/quicksettings/quicksettings";
import { AuthUserContext } from "../util/session";

const CourseUserContext = React.createContext({});
export { CourseUserContext };

const useCourseUser = (update = false, updateCb = () => {}) => {
  const course = useContext(CourseContext) ?? {};
  const overrideSettings = useContext(QuickSettingsContext);
  const authUser = useContext(AuthUserContext);
  const firebase = useContext(FirebaseContext);
  const [courseUser, setCourseUser] = useState({});
  const [defaultCourseUser, setDefaultCourseUser] = useState({});
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const updateCallback = useCallback(updateCb, [updateCb]);

  const loadCourseUser = () => setShouldFetchData(true);
  const loadCourseUserPromise = () => updateCourseUser();

  const loadCourseYAMLTags = useCallback(() => {
    const { coursetags } = course.settings ?? {};

    if (!coursetags || !authUser) return;

    const tags = {};

    for (let tag of coursetags) {
      if (!tag.emails?.includes(authUser.email)) continue;

      tags[tag.id] = { authorizedBy: "course-yaml", timestamp: Date.now() };
    }

    return { tags };
  }, [authUser, course.settings]);

  const updateCourseUser = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (authUser === null) {
        updateCallback(false);
        setShouldFetchData(false);
        setIsLoading(false);
        return;
      }

      setShouldFetchData(false);
      setIsLoading(true);

      // isLoading = true
      updateCallback(true);

      firebase
        .getCourseUser(course.id)
        .then((res) => {
          const cUser = res ?? {};

          // predefined tags in
          const predefinedTags = loadCourseYAMLTags();

          if (cUser.tags?.fixedTagsPath) {
            delete cUser.tags.fixedTagsPath;
          }

          const currentSettings = {};
          const defaultSettings = {};

          [cUser, predefinedTags, overrideSettings.courseUser].forEach((c, i, arr) => {
            if (typeof c === "object" && c !== null) {
              for (const key of Object.keys(c)) {
                if (!currentSettings.hasOwnProperty(key)) currentSettings[key] = {};
                if (!defaultSettings.hasOwnProperty(key)) defaultSettings[key] = {};

                if (typeof c[key] === "string") {
                  currentSettings[key] = c[key];
                } else {
                  currentSettings[key] = { ...currentSettings[key], ...c[key] };
                }

                if (i < arr.length - 1) {
                  if (typeof c[key] === "string") {
                    defaultSettings[key] = c[key];
                  } else {
                    defaultSettings[key] = { ...currentSettings[key], ...c[key] };
                  }
                }
              }
            }
          });
          setCourseUser(currentSettings);
          setDefaultCourseUser(defaultSettings);
        })
        .then(() => {
          setIsLoading(false);
          // isLoading = false
          updateCallback(false);
          resolve();
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
          updateCallback(false);
          reject(e);
        });
    });
  }, [firebase, authUser, course.id, loadCourseYAMLTags, overrideSettings.courseUser, updateCallback]);

  useEffect(() => {
    if (!shouldFetchData || authUser === false) return;

    updateCourseUser();
  }, [shouldFetchData, authUser, updateCourseUser]);

  useEffect(() => {
    if (update) setShouldFetchData(update);
  }, [update, shouldFetchData]);

  useEffect(() => {
    if (!overrideSettings?.courseUser) return setCourseUser({ ...defaultCourseUser });

    setCourseUser((state) => {
      if (!state) state = {};

      for (const key of Object.keys(overrideSettings.courseUser)) {
        if (typeof overrideSettings.courseUser[key] === "string") {
          state[key] = overrideSettings.courseUser[key];
        } else {
          state[key] = { ...overrideSettings.courseUser[key] };
        }
      }

      return { ...state };
    });
  }, [overrideSettings, defaultCourseUser]);

  return {
    current: courseUser,
    default: defaultCourseUser,
    isLoading: isLoading,
    loadCourseUser,
    loadCourseUserPromise,
  };
};

export const withCourseUser = (Component) => {
  return (props) => {
    const courseUser = useCourseUser();

    return (
      <CourseUserContext.Provider value={courseUser}>
        <Component {...props} courseUser={courseUser} />
      </CourseUserContext.Provider>
    );
  };
};

export const withCourseUserContext = (Component) => {
  return (props) => {
    const courseUser = useContext(CourseUserContext);

    return <Component {...props} courseUser={courseUser} />;
  };
};

export default useCourseUser;
