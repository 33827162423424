const edukamuConfig = {
  apiKey: "AIzaSyDFfr8lYNUfd0unKbMtk_ClnpCA-l-iaLs",
  authDomain: "edukamu-92142.firebaseapp.com",
  databaseURL: "https://edukamu-92142.firebaseio.com",
  projectId: "edukamu-92142",
  storageBucket: "edukamu-92142.appspot.com",
  messagingSenderId: "522984874174",
  appId: "1:522984874174:web:6d84864c292d180d5ffeef",
  measurementId: "G-8XNMJW8LQ8",
};

const edukamuDevConfig = {
  apiKey: "AIzaSyCugrZO93Sy5rHFDAOeB3yQmEpsQAN6eg4",
  authDomain: "dev-edukamu.firebaseapp.com",
  databaseURL: "https://dev-edukamu.firebaseio.com",
  projectId: "dev-edukamu",
  storageBucket: "dev-edukamu.appspot.com",
  messagingSenderId: "457580532183",
  appId: "1:457580532183:web:bc68787688c4f30244bb92",
  measurementId: "G-6FCG355M8Q",
};

const edukamuDevMscConfig = {
  apiKey: "AIzaSyCugrZO93Sy5rHFDAOeB3yQmEpsQAN6eg4",
  authDomain: "dev-edukamu.firebaseapp.com",
  databaseURL: "https://dev-edukamu-mc.europe-west1.firebasedatabase.app",
  projectId: "dev-edukamu",
  storageBucket: "dev-edukamu.appspot.com",
  messagingSenderId: "457580532183",
  appId: "1:457580532183:web:bc68787688c4f30244bb92",
  measurementId: "G-6FCG355M8Q",
};

const edukamuMscConfig = {
  apiKey: "AIzaSyDFfr8lYNUfd0unKbMtk_ClnpCA-l-iaLs",
  authDomain: "edukamu-92142.firebaseapp.com",
  databaseURL: "https://msc-edukamu.europe-west1.firebasedatabase.app",
  projectId: "edukamu-92142",
  storageBucket: "edukamu-92142.appspot.com",
  messagingSenderId: "522984874174",
  appId: "1:522984874174:web:6d84864c292d180d5ffeef",
  measurementId: "G-8XNMJW8LQ8",
};

const edukamuAIEduConfig = {
  apiKey: "AIzaSyDFfr8lYNUfd0unKbMtk_ClnpCA-l-iaLs",
  authDomain: "edukamu-92142.firebaseapp.com",
  databaseURL: "https://aiedu-edukamu.europe-west1.firebasedatabase.app",
  projectId: "edukamu-92142",
  storageBucket: "edukamu-92142.appspot.com",
  messagingSenderId: "522984874174",
  appId: "1:522984874174:web:6d84864c292d180d5ffeef",
  measurementId: "G-8XNMJW8LQ8",
};

const edukamuDKVConfig = {
  apiKey: "AIzaSyDFfr8lYNUfd0unKbMtk_ClnpCA-l-iaLs",
  authDomain: "edukamu-92142.firebaseapp.com",
  databaseURL: "https://dkv-edukamu.europe-west1.firebasedatabase.app",
  projectId: "edukamu-92142",
  storageBucket: "edukamu-92142.appspot.com",
  messagingSenderId: "522984874174",
  appId: "1:522984874174:web:6d84864c292d180d5ffeef",
  measurementId: "G-8XNMJW8LQ8",
};

const edukamukveduConfig = {
  apiKey: "AIzaSyDFfr8lYNUfd0unKbMtk_ClnpCA-l-iaLs",
  authDomain: "edukamu-92142.firebaseapp.com",
  databaseURL: "https://kvedu-edukamu.europe-west1.firebasedatabase.app",
  projectId: "edukamu-92142",
  storageBucket: "edukamu-92142.appspot.com",
  messagingSenderId: "522984874174",
  appId: "1:522984874174:web:6d84864c292d180d5ffeef",
  measurementId: "G-8XNMJW8LQ8",
};

const edukamukvindiaConfig = {
  apiKey: "AIzaSyDFfr8lYNUfd0unKbMtk_ClnpCA-l-iaLs",
  authDomain: "edukamu-92142.firebaseapp.com",
  databaseURL: "https://kvedu-edukamu-india.europe-west1.firebasedatabase.app",
  projectId: "edukamu-92142",
  storageBucket: "edukamu-92142.appspot.com",
  messagingSenderId: "522984874174",
  appId: "1:522984874174:web:6d84864c292d180d5ffeef",
  measurementId: "G-8XNMJW8LQ8",
};

/*
const edukamuNodeConfig = {
  apiKey: "AIzaSyB-3IYK88EYkPi9H8NrEtspJnZ_QiM8zxg",
  authDomain: "dev-edukamu-node.firebaseapp.com",
  databaseURL: "https://dev-edukamu-node.firebaseio.com",
  projectId: "dev-edukamu-node",
  storageBucket: "dev-edukamu-node.appspot.com",
  messagingSenderId: "1074569822693",
  appId: "1:1074569822693:web:d34a1509cb722d9870af0c",
  measurementId: "G-65EWDM8K9P",
};
*/

export function getConfig() {
  const hosti = window.location.hostname 
  var retStr= false;
  switch (hosti) {
      case "127.0.0.1":
      case "localhost":
      case "dev.edukamu.fi":
      case "dev2.edukamu.fi":
      case "dev2-edukamu.web.app":  
      case "dev3.edukamu.fi":
      case "dev4.edukamu.fi":
      case "test.edukamu.fi":
        retStr = edukamuDevConfig; 
        break;
      case "devmsc.edukamu.fi":
        retStr = edukamuDevMscConfig; 
        break;
      case "cs.edukamu.fi":
        retStr = edukamuMscConfig; 
        break;
      case "aiedu.edukamu.fi":
        retStr = edukamuAIEduConfig; 
        break;
      // kun tiedetaan hostname
      case "ms.edukamu.fi":
        retStr = edukamukveduConfig;
        break;
      case "india.edukamu.fi":
        retStr = edukamukvindiaConfig;
        break; 
      case "kurssit.kunnollavauhtiin.fi":
      case "materiaali.kunnollavauhtiin.fi":
      case "courses.officeskillsaccelerator.com":
      case "material.officeskillsaccelerator.com":
        retStr = edukamuDKVConfig; 
        break;
      default : 
        retStr =edukamuConfig; 
  }
  return retStr;
}

export function checkMscConfig() {
  const hosti = window.location.hostname 
  var retStr= false;
  switch (hosti) {
      case "ms.edukamu.fi":
        retStr = edukamukveduConfig.databaseURL;
        break; 
      case "india.edukamu.fi":
        retStr = edukamukvindiaConfig.databaseURL;
        break; 
      case "devmsc.edukamu.fi":
        retStr = edukamuDevMscConfig.databaseURL; 
        break;
      case "cs.edukamu.fi":
        retStr = edukamuMscConfig.databaseURL; 
        break;
      case "aiedu.edukamu.fi":
        retStr = edukamuAIEduConfig.databaseURL; 
        break;
      case "kurssit.kunnollavauhtiin.fi":
      case "materiaali.kunnollavauhtiin.fi":
      case "courses.officeskillsaccelerator.com":
      case "material.officeskillsaccelerator.com":
        retStr = edukamuDKVConfig.databaseURL; 
        break;
      default : 
        retStr = false;
  }
  return retStr;
 
}