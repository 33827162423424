import React, { useContext } from "react";

export const QuickSettingsContext = React.createContext({
  defaults: {},
  options: {},
  courseUser: {},
  setQuickSettingsValue: () => {},
  clearQuickSettings: () => {},
  getQuickSettingsValue: () => {
    return { a: 1, b: 2 };
  },
});

export const withQuickSettings = (Component) => {
  return (props) => {
    const quickSettings = useContext(QuickSettingsContext);

    return <Component {...props} quickSettings={quickSettings} />;
  };
};
