import React, { Suspense, lazy, useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { safeLoad } from "js-yaml";

import { withFirebase } from "./util/firebase";
import { withAuthentication, withRegistration } from "./util/session";
import { CourseContext } from "./util/course";
import { GeneralSettingsContext } from "./util/generalsettings";

//import AuthenticatedRoute from "./util/route/authenticatedroute";
import UnauthenticatedRoute from "./util/route/unauthenticatedroute";
import PasswordRoute from "./util/route/password/passwordroute";
import ErrorBoundary from "./components/errorboundary";
import BrowserCheck from "./components/browser-check/browser-check";

import { objectKeysToLowercase, getProperty, fixUrlsFromObj, setProperty, fixUrl } from "./util/util";
import useFontLoader from "./util/fontloader";
import { extractHostname } from "./util/host";
import { convertToArray } from "./util/array";
import { fetchYAML, importJson, logImportErrors } from "./util/course/import";
import { QuickSettingsContext } from "./util/quicksettings/quicksettings";
import { CookiePreferencesForm } from "./components/cookie-preferences/cookie-preferences";
import { ActionRedirect } from "./components/ActionRedirect/ActionRedirect";


import merge from "lodash.merge";

import "./App.scss";

// Pages
//const Home = lazy(() => import("./pages/edukamu/Home"));
//const Contact = lazy(() => import("./pages/edukamu/Contact"));
const Profile = lazy(() => import("./pages/edukamu/Profile"));
const Course = lazy(() => import("./pages/edukamu/Course"));
const Chapter = lazy(() => import("./pages/edukamu/Chapter"));
const LMSContent = lazy(() => import("./pages/edukamu/LMSContent"));
const Progress = lazy(() => import("./pages/edukamu/Progress"));
//const Login = lazy(() => import("./pages/login"));
const LoginPW = lazy(() => import("./pages/loginpw"));
const Error = lazy(() => import("./pages/error"));

function App(props) {

  const setSettingOverrideValue = (name, value, key = "options") => {
    if (!overrideSettings[key]) overrideSettings[key] = {};

    overrideSettings[key][name] = value;

    setOverrideSettings({ ...overrideSettings });

    if (key === "options") return storeQuickSettings(overrideSettings[key]);

    storeQuickSettings(overrideSettings[key], key);
  };

  const clearSettings = (defaultCourseUser = {}) => {
    overrideSettings.options = {};
    overrideSettings.courseUser = defaultCourseUser;

    setOverrideSettings({ ...overrideSettings });

    clearQuickSettings();
  };

  const processGeneralSettings = (general) => {
    if (general?.settings?.termsofservice?.url) {
      general.settings.termsofservice.url = getCoursePath(general.settings.termsofservice.url);
    }
    if (general?.settings?.privacypolicy?.url) {
      general.settings.privacypolicy.url = getCoursePath(general.settings.privacypolicy.url);
    }
    return general;
  };

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticatedPW, userHasAuthenticatedPW] = useState(false);
  const [isAuthenticatedVoucher, userHasAuthenticatedVoucher] = useState(false);
  const [overrideSettings, setOverrideSettings] = useState({
    defaults: {},
    options: {},
    courseUser: null,
    setQuickSettingsValue: setSettingOverrideValue,
    clearQuickSettings: clearSettings,
  });

  var appProps = {
    isAuthenticated: isAuthenticated,
    userHasAuthenticated: userHasAuthenticated,
    isAuthenticatedPW: isAuthenticatedPW,
    userHasAuthenticatedPW: userHasAuthenticatedPW,

    isAuthenticatedVoucher: isAuthenticatedVoucher,
    userHasAuthenticatedVoucher: userHasAuthenticatedVoucher,
  };

  const { i18n } = useTranslation();
  const [generalSettings, setGeneralSettings] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState([true]);
  const [error, setError] = useState(null);
  const [fonts, setFonts] = useState({ fonts: [] });
  const [redirectInfo, setRedirectInfo] = useState(null);

  useFontLoader(fonts);

  useEffect(() => {
    // Firebase settings from host function
    //
    if (window.EDUKAMU_SETTINGS) {
      if (window.EDUKAMU_SETTINGS.lang != null) {
        if (i18n.language !== window.EDUKAMU_SETTINGS) {
          i18n.changeLanguage(window.EDUKAMU_SETTINGS.lang);
        }
      }

      setGeneralSettings(
        processGeneralSettings({
          settings: window.EDUKAMU_SETTINGS.settings,
          courses: window.EDUKAMU_SETTINGS.courses,
        })
      );

      // Filter the courses array to requested course,
      // do this after setting original courses to generalSettings.
      //
      // window.EDUKAMU_SETTINGS.courses = [getCurrentCourse(window.EDUKAMU_SETTINGS.courses)];

      applyTranslations(window.EDUKAMU_SETTINGS.courses);

      const courseFonts = getCourseFonts(window.EDUKAMU_SETTINGS.courses);
      if (courseFonts && Array.isArray(courseFonts)) {
        setFonts({
          fonts: courseFonts,
        });
      }

      setRedirectInfo(getRedirectInfo(window.EDUKAMU_SETTINGS.courses));

      let quickSettings = getQuickSettings(window.EDUKAMU_SETTINGS.courses);
      let courseUserSettings = getQuickSettings(window.EDUKAMU_SETTINGS.courses, "courseUser");

      for (let key in quickSettings) {
        setSettingOverrideValue(key, quickSettings[key]);
      }

      for (let key in courseUserSettings) {
        setSettingOverrideValue(key, courseUserSettings[key], "courseUser");
      }

      // setCourses(window.EDUKAMU_SETTINGS.courses);
      // setLoading(false);

      // TODO: This modules 'contentUrl' functionality is also implemented in import feature
      // This should be unified
      Promise.all(
        window.EDUKAMU_SETTINGS.courses.map(async (course) => {
          if (course.modules) {
            course.modules = await handleModuleContent(course);
          }

          return { ...course };
        })
      )
        .then((res) => {
          logImportErrors(res);
          setCourses(res);
        })
        .catch((e) => {
          logImportErrors(window.EDUKAMU_SETTINGS.courses);
          console.error(e);
          setCourses(window.EDUKAMU_SETTINGS.courses);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // Fallback - fetch settings
      //
      let fb = props.firebase;
      getCourses(fb)
        .then((result) => {
          if (result.lang != null) {
            //document.documentElement.lang = result.lang;
            if (i18n.language !== result.lang) {
              i18n.changeLanguage(result.lang);
            }
          }
          setGeneralSettings(
            processGeneralSettings({
              settings: result.settings,
              courses: result.courses,
            })
          );

          getCourse(result.courses).then(
            (resultArray) => {
              const currentCourse = getCurrentCourse(resultArray);

              // Filter the courses array to only requested course..
              //
              resultArray = [];

              if (currentCourse) {
                resultArray = [currentCourse];
                fb.initAnswers(currentCourse.id)
              }

              applyTranslations(resultArray);

              const courseFonts = getCourseFonts(resultArray);
              if (courseFonts && Array.isArray(courseFonts)) {
                setFonts({
                  fonts: courseFonts,
                });
              }

              setRedirectInfo(getRedirectInfo(resultArray));

              // let quickSettings = getQuickSettings(resultArray);
              // for (let key in quickSettings) {
              //   setSettingOverrideValue(key, quickSettings[key]);
              // }
              let quickSettings = getQuickSettings(resultArray);
              let courseUserSettings = getQuickSettings(resultArray, "courseUser");

              for (let key in quickSettings) {
                setSettingOverrideValue(key, quickSettings[key]);
              }

              for (let key in courseUserSettings) {
                setSettingOverrideValue(key, courseUserSettings[key], "courseUser");
              }

              logImportErrors(resultArray);
              setCourses(resultArray);
              setLoading(false);
            },
            (rejected) => {
              setLoading(false);
              setError(rejected);
            }
          );
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          setError(error);
        });
    }
  }, [i18n]); // eslint-disable-line react-hooks/exhaustive-deps

  let titleEl = document.getElementById("title");

  if (["/", "/otayhteytta"].includes(window.location.pathname) && titleEl) {
    titleEl.innerText = "Edukamu";
  } else {
    // Document title is processed in Firebase "host" function - this is only a fallback
    //
    if (!window.EDUKAMU_SETTINGS) {
      let path = getCurrentPath();
      if (path) {
        for (const course of courses) {
          if (course.url === path) {
            if (titleEl) {
              //titleEl.innerText = `Edukamu: ${course.name}`;
              titleEl.innerText = getProperty("settings.html.title", course);
            }
            break;
          }
        }
      }
    }
  }

  function applyTranslations(courses) {
    const path = getCurrentPath();

    for (const c of courses) {
      if (c.url === path) {
        const translations = getProperty("settings.i18n.translations", c, null);

        if (translations) {
          for (var lang in translations) {
            for (var i = 0; i < translations[lang].length; i++) {
              i18n.addResource(lang, "translation", translations[lang][i].key, translations[lang][i].value);
            }
          }
        }

        break;
      }
    }
  }

  return (
    <main>
      <QuickSettingsContext.Provider value={overrideSettings}>
        <GeneralSettingsContext.Provider value={generalSettings}>
          <ErrorBoundary showError={true}>
            <Suspense fallback={<div></div>}>
              <BrowserCheck />
              <Switch>
                {/* <UnauthenticatedRoute checkAuth={true} path="/login" exact component={Login} appProps={appProps} /> */}
                <UnauthenticatedRoute
                  checkAuthPW={true}
                  path="/loginpw"
                  exact
                  component={LoginPW}
                  appProps={appProps}
                  coursePassword={redirectInfo ? redirectInfo.password : null}
                  courseName={redirectInfo ? redirectInfo.name : null}
                />
                {/* <AuthenticatedRoute path="/" exact appProps={appProps}>
                    <Home />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/otayhteytta" exact appProps={appProps}>
                    <Contact />
                  </AuthenticatedRoute> */}

                <Route path="/action" >
                  <ActionRedirect />
                </Route>
                <Route path="/profiili" exact appProps={appProps}>
                  <Profile />
                  <CookiePreferencesForm />
                </Route>
                <Route exact path="/">
                  <Redirect to="/edukamufi" />
                </Route>


                {courses.map((course, index, array) => {
                  const progressDisabled = getProperty("progress.disabled", course);

                  if (!course.originalsettings) {
                    course.originalsettings = retrieveDefaultSettings(course);
                  }

                  const pwRoutes = [
                    <PasswordRoute
                      path={`/${course.url}`}
                      exact
                      appProps={appProps}
                      key={index}
                      courseName={course.url}
                      coursePassword={getCoursePassword(course)}
                      course={course}
                    >
                      <CourseContext.Provider value={course}>
                        <Course />
                        <CookiePreferencesForm />
                      </CourseContext.Provider>
                    </PasswordRoute>,
                  ];

                  if (progressDisabled !== true) {
                    pwRoutes.push(
                      <PasswordRoute
                        courseName={course.url}
                        coursePassword={getCoursePassword(course)}
                        path={`/${course.url}/progress`}
                        exact
                        appProps={appProps}
                        key={index + "-progress"}
                        course={course}
                      >
                        <CourseContext.Provider value={course}>
                          <Progress />
                          <CookiePreferencesForm />
                        </CourseContext.Provider>
                      </PasswordRoute>
                    );
                  }
                  return pwRoutes;
                })}
                {courses.map((course) => {
                  if (!course.chapters || !course.chapters.length) return null;
                  return course.chapters.map((chapter, index) => {
                    const isLMSContent = getProperty("articulatecontent", chapter) !== null;

                    if (!course.originalsettings) {
                      course.originalsettings = retrieveDefaultSettings(course);
                    }

                    return (
                      <PasswordRoute
                        path={`/${course.url}/${chapter.urlname}`}
                        exact
                        appProps={appProps}
                        key={index}
                        courseName={course.url}
                        coursePassword={getCoursePassword(course)}
                      >
                        <CourseContext.Provider value={course}>
                          {isLMSContent ? <LMSContent chapter={chapter} /> : <Chapter chapter={chapter} />}
                          <CookiePreferencesForm />
                        </CourseContext.Provider>
                      </PasswordRoute>
                    );
                  });
                })}
                <Route>
                  {loading ? null : (
                    <div>
                      {error ? (
                        <div style={{ width: "100%" }}>
                          <h3 style={{ color: "red" }}>Error parsing YAML:</h3>
                          <pre style={{ border: "1px solid black" }}>{JSON.stringify(error, null, 4)}</pre>
                        </div>
                      ) : (
                        <Error courses={courses}></Error>
                      )}
                    </div>
                  )}
                </Route>
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </GeneralSettingsContext.Provider>
      </QuickSettingsContext.Provider>
    </main>
  );
}

async function handleModuleContent(course = {}) {
  const { modules } = course;

  if (!modules) return { ...course };

  const moduleKeys = Object.keys(modules);

  for (let key of moduleKeys) {
    const m = modules[key];

    if (!m.hasOwnProperty("contenturl") || !course.coursepath) {
      modules[key] = m;
      continue;
    }

    try {
      const yamlData = objectKeysToLowercase(await fetchYAML(fixUrl(m.contenturl, course.coursepath)));

      modules[key] = { ...m, content: yamlData?.settings?.modules };
    } catch (e) {
      console.error("Fetching module content from URL failed -- ", e);
      modules[key] = m;
    }
  }

  return { ...modules };
}

function getCurrentCourse(courses) {
  const path = getCurrentPath();

  for (const c of courses) {
    if (c.url === path) {
      return c;
    }
  }

  return null;
}

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return false;
}

/**
 * Varmistetaan että tiedetään aina kurssin pw/name arvot
 * Tämä pitänee yhdentää sopivaan contextiin
 * @param {*} courses
 */
function getRedirectInfo(courses) {
  const path = getCurrentPath();

  for (const c of courses) {
    if (c.url === path && getCoursePassword(c)) {
      return {
        password: getCoursePassword(c),
        name: c.url,
      };
    }
  }
  return null;
}

function retrieveDefaultSettings(course) {
  const obj = {
    quicksettings: true,
    "progress.disabled": getProperty("progress.disabled", course, null),
    "progress.disableprogressrequired": getProperty("progress.disableprogressrequired", course, null),
    "progress.completeinorder.enabled": getProperty("progress.completeinorder.enabled", course, null),
    "module.showdisabled": getProperty("module.showdisabled", course, null),
    theme: "yaml",
    clearbasetheme: true,
  };

  return obj;
}

function getQuickSettings(courses, prefix = "") {
  const path = getCurrentPath();
  const pre = prefix ? prefix + "-" : "";

  for (const c of courses) {
    if (c.url === path) {
      const item = localStorage.getItem(path + "_" + pre + "settings");

      if (item) {
        return JSON.parse(item);
      }

      return {};
    }
  }

  return null;
}

function clearQuickSettings() {
  const path = getCurrentPath();

  localStorage.removeItem(path + "_settings");
  localStorage.removeItem(path + "_courseUser-settings");
}

function storeQuickSettings(settings, prefix) {
  const path = getCurrentPath();
  const pre = prefix ? prefix + "-" : "";

  localStorage.setItem(path + "_" + pre + "settings", JSON.stringify(settings));
}

function getCurrentPath() {
  let path = null;
  try {
    var redirect = getRedirectPath();

    if (redirect) {
      path = redirect;
    } else {
      path = window.location.pathname.split("/")[1];
    }
  } catch (err) {
    console.warn(err);
  }
  return path;
}

function getRedirectPath() {
  let path = null;

  try {
    if (window.location.search && window.location.search.indexOf("redirect=") !== -1) {
      path = getQueryVariable("redirect");

      if (path) {
        // Remove "/" from begin if there is one (there should be)
        //
        if (path.indexOf("/") === 0) {
          path = path.substr(1);
        }
      }
    }
  } catch (err) {
    console.warn(err);
  }
  return path;
}

function getCourseFonts(courses) {
  const path = getCurrentPath();
  for (const course of courses) {
    if (course.url === path) {
      return getProperty("style.fonts", course);
    }
  }
  return null;
}

function getCoursePassword(course) {
  if (!course) {
    return;
  }

  // Default
  //
  let password = course.password;

  const hostname = extractHostname(window.location.href);
  //const hostname = "stage.edukamu.fi";

  // By domain name may override default
  //
  if (course.hasOwnProperty("domains")) {
    if (course.domains[hostname]) {
      if (course.domains[hostname].hasOwnProperty("password")) {
        password = course.domains[hostname]["password"];
      }
    }
  }

  // HACK Make sure that landing course doesn't require password.
  // TODO: Find out why Redirect route doesn't get correct password setting for landing course!
  if (course.name === "edukamu.fi") {
    password = "none";
  }

  return password;
}

function getCourse(courseArray) {
  return new Promise((resolve, reject) => {
    var path = getCurrentPath();

    if (path === "loginpw") {
      path = getRedirectPath();
    }

    if (path == null) {
      reject();
      return;
    }

    var hostname = extractHostname(window.location.href);
    if (hostname === "localhost") {
      hostname = "dev.edukamu.fi"
    }
    // filter courses by domain

    var currentCourse = courseArray.find((c) => c.url === path);

    if (currentCourse) {
      // tarpeeton kun alamme uskoa domains kohtaa oikein.
      //if (!currentCourse.hasOwnProperty("coursecontent")) {
      //  resolve(courseArray);
      //  return;

      // asetetaan defaultit tyhjiksi !!
      if (currentCourse.hasOwnProperty("coursecontent")) {
        currentCourse.coursecontent = "";
      }
      if (currentCourse.hasOwnProperty("coursepath")) {
        currentCourse.coursepath = "";
      }

      // Apply this course "coursecontent" and "coursepath" if they are set for this domain
      //
      if (currentCourse.hasOwnProperty("domains")) {
        if (currentCourse.domains[hostname]) {
          if (currentCourse.domains[hostname].hasOwnProperty("coursepath")) {
            currentCourse.coursepath = currentCourse.domains[hostname].coursepath;
          }

          if (currentCourse.domains[hostname].hasOwnProperty("coursecontent")) {
            currentCourse.coursecontent = currentCourse.domains[hostname].coursecontent;
          }
        }
      }
      if (currentCourse.coursecontent === undefined) {
        // jos ei kurssia domainilla niin poistetaan palautuksesta kurssin tiedot,
        // saadaan aikaan 404 sivu. 
        const objIndex = courseArray.findIndex((c) => c.url === path);
        if (objIndex > -1) {
          courseArray[objIndex] = ""
          resolve(courseArray);
        } else {
          resolve(courseArray);
        }

      }
      else {
        fetch(currentCourse.coursecontent)
          .then((response) => {
            if (!response.ok) {
              return reject(response);
            }

            return response.text();
          })
          .then(async (text) => {
            try {
              if (currentCourse.coursepath) {
                let courseJSON = safeLoad(text);
                courseJSON = await importJson(courseJSON, currentCourse.coursepath, { log: true });
                return fixUrlsFromObj(objectKeysToLowercase(courseJSON), currentCourse.coursepath);
                //return fixUrlsFromText(objectKeysToLowercase(safeLoad(text)), currentCourse.coursepath);
              } else {
                return objectKeysToLowercase(safeLoad(text));
              }
            } catch (error) {
              reject(error);
            }
          })
          .then((courseJSON) => {
            return new Promise((resolve, reject) => {
              const feedbackUrl = getProperty("settings.feedback.url", courseJSON);
              if (feedbackUrl) {
                fetch(feedbackUrl)
                  .then((response) => {
                    response.text().then((text) => {
                      if (currentCourse.coursepath) {
                        courseJSON.settings.feedback.data = fixUrlsFromObj(
                          objectKeysToLowercase(safeLoad(text)),
                          currentCourse.coursepath
                        );
                        //courseJSON.settings.feedback.data = fixUrlsFromText(objectKeysToLowercase(safeLoad(text)), currentCourse.coursepath);
                      } else {
                        courseJSON.settings.feedback.data = objectKeysToLowercase(safeLoad(text));
                      }

                      resolve(courseJSON);
                    });
                  })
                  .catch((error) => {
                    resolve(courseJSON);
                  });
              } else {
                resolve(courseJSON);
              }
            });
          })
          .then((courseJSON) => {
            return new Promise((resolve, reject) => {
              const themes = getProperty("settings.themes.url", courseJSON);

              // Force themes as array
              //
              const themeArray = [].concat(themes || []);

              var dynamicThemes = {};

              // Load themes from yaml-files
              //
              themeArray.forEach((themeUrl) => {
                fetch(themeUrl)
                  .then((response) => {
                    response.text().then((text) => {
                      if (currentCourse.coursepath) {
                        merge(
                          dynamicThemes,
                          fixUrlsFromObj(objectKeysToLowercase(safeLoad(text)), currentCourse.coursepath)
                        );
                      } else {
                        merge(dynamicThemes, objectKeysToLowercase(safeLoad(text)));
                      }
                    });
                  })
                  .catch((error) => {
                    resolve(courseJSON);
                  });
              });

              setProperty("settings.themes.dynamicthemes", courseJSON, dynamicThemes);
              //courseJSON.settings.themes.dynamicthemes = dynamicThemes;

              resolve(courseJSON);
            });
          })
          .then((courseJSON) => {
            // build chapters data
            let chapterArray = [];
            if (courseJSON && courseJSON.chapters) {
              chapterArray = Object.keys(courseJSON.chapters).map((chapterKey) => {
                return courseJSON.chapters[chapterKey];
              });
            }

            if (courseJSON?.settings?.coursetags) {
              courseJSON.settings.coursetags = convertToArray(courseJSON.settings.coursetags, { includeKeyAsId: true });
            }

            // merge course content fetch result
            const objIndex = courseArray.findIndex((c) => c.url === path);
            if (objIndex > -1) {
              Object.assign(courseArray[objIndex], courseJSON);
              courseArray[objIndex].chapters = chapterArray;
            }

            // resolve(courseArray);
            return courseArray;
          })
          .then((courseArray) => {
            // handling modules contentUrl...
            // adds content to module from separate module YAML
            Promise.all(
              courseArray.map(async (course) => {
                if (course.modules) {
                  course.modules = await handleModuleContent(course);
                }
                return { ...course };
              })
            )
              .then(async (courseArray) => {
                resolve(courseArray);
              })
              .catch((e) => {
                console.error("Fetching module content from URL failed -- ", e);
                resolve(courseArray);
              });
          })
          .catch((error) => {
            reject(error);
          });

      }

    } else {
      // no changes, resolve as is
      resolve(courseArray);
    }
  });
}

function getCoursePath(file = "courses.yaml") {
  if (file.startsWith("/")) {
    file = file.slice(1);
  }
  const hostname = extractHostname(window.location.href);
  let coursePath;

  switch (hostname) {
    case "127.0.0.1":
    case "localhost":
    case "dev.edukamu.fi":
    case "dev2.edukamu.fi":
    case "dev3.edukamu.fi":
    case "dev4.edukamu.fi":
    case "staging.edukamu.fi":
      coursePath = `https://dev-courses.web.app/${file}`;
      break;
    case "test.edukamu.fi":
    case "test2.edukamu.fi":
      coursePath = `https://test-courses.web.app/${file}`;
      break;
    case "stage.edukamu.fi":
    case "stage2.edukamu.fi":
      coursePath = `https://stage-courses.web.app/${file}`;
      break;
    case "cs.edukamu.fi":
      if (file === "courses.yaml") {
        coursePath = `https://edukamu-courses.web.app/msc-courses.yaml`;
      } else {
        coursePath = `https://edukamu-courses.web.app/${file}`;
      }
      break;
    case "ms.edukamu.fi":
      if (file === "courses.yaml") {
        coursePath = `https://edukamu-courses.web.app/kvedu-courses.yaml`;
      } else {
        coursePath = `https://edukamu-courses.web.app/${file}`;
      }
      break;
    case "india.edukamu.fi":
      if (file === "courses.yaml") {
        coursePath = `https://edukamu-courses.web.app/kvedu-india-courses.yaml`;
      } else {
        coursePath = `https://edukamu-courses.web.app/${file}`;
      }
      break;
    case "edukamu.fi":
      coursePath = `https://edukamu-courses.web.app/${file}`;
      break;
    case "esvenska.fi":
      coursePath = `https://edukamu-courses.web.app/${file}`;
      break;
    case "oamk.esvenska.fi":
      coursePath = `https://edukamu-courses.web.app/${file}`;
      break;
    case "jumppakamu.fi":
      coursePath = `https://edukamu-courses.web.app/${file}`;
      break;
    case "aiedu.edukamu.fi":
      if (file === "courses.yaml") {
        coursePath = `https://edukamu-courses.web.app/courses.yaml`;
      } else {
        coursePath = `https://edukamu-courses.web.app/${file}`;
      }
      break;
    case "kurssit.kunnollavauhtiin.fi":
    case "materiaali.kunnollavauhtiin.fi":
    case "material.officeskillsaccelerator.com":
    case "courses.officeskillsaccelerator.com":
      // tähän muutos kun oma kanta ja oma courses.yaml käytössä
      if (file === "courses.yaml") {
        coursePath = `https://edukamu-courses.web.app/courses.yaml`;
      } else {
        coursePath = `https://edukamu-courses.web.app/${file}`;
      }
      break;
    default:
      coursePath = `https://edukamu-courses.web.app/${file}`;
      break;
  }

  return coursePath;
}

function getCourses(fb) {
  return new Promise((resolve, reject) => {
    const hostname = extractHostname(window.location.href);
    const key = getCurrentPath();
    // parsecourse
    fb.getOnlyCourseYaml(hostname, key).then(async (data) => {
      resolve(data);
    })
      .catch((error) => {
        reject(error);
      });
  });
}

export default withFirebase(withRegistration(withAuthentication(App)));