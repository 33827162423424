import React, { useState, useEffect } from "react";

const Checkbox = ({
  className = "edukamu-checkbox",
  disabled = false,
  label = "",
  id = "input",
  onChange = () => {},
  checked,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
    onChange(e);
  };

  useEffect(() => {
    if (typeof checked !== "boolean") return;

    setIsChecked(checked);
  }, [checked]);

  return (
    <label className={`${className}${disabled ? "--disabled" : ""}`}>
      <input checked={isChecked} type="checkbox" id={id} onChange={handleChange} disabled={disabled} />
      <span className={`${className}__input${disabled ? "--disabled" : ""}`}></span>
      {label}
    </label>
  );
};

export default Checkbox;
