
import merge from "lodash.merge";
import { safeLoad } from "js-yaml";
import { fixUrl, objectKeysToLowercase } from "../util";

export const fetchYAML = async (url) => {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((res) => {
          if (res.status !== 200) reject(res.status);
  
          return res.text();
        })
        .then((res) => safeLoad(res))
        .then((json) => resolve(json))
        .catch((e) => reject(e));
    });
  };
  
  /**
   * Check object for import keys and merge
   *
   * Note: Function should be almost indentical with recursiveImport found from functions/course.js
   *
   * @typedef {Object} ImportObject
   * @param {Object} obj - Viewed object
   * @param {string} coursepath - Courses path from courses.yaml
   * @param {string|undefined} [key] - Key that is being viewed from parent object
   * @param {string[]} [path=[]] - Current path to object
   * @param {string[]} [files=[]] - Filepaths that have been already downloaded in current path
   *
   * @param {ImportObject}
   * @returns
   */
  const recursiveImport = async ({ obj, key, path = [], files = [], coursepath, errors = [] }) => {
    const importKeys = ["fileUrl", "import"];
  
    const typeCheck = (obj, key) => {
      if (obj !== null && typeof obj !== "undefined" && Array.isArray(obj)) {
        if (!importKeys.includes(key)) {
          return { type: "array", valid: false };
        }
  
        return { type: "array", valid: obj.every((c) => typeof c === "string") };
      }
  
      if (obj !== null && typeof obj !== "undefined" && typeof obj === "object" && !Array.isArray(obj)) {
        return { type: "object", valid: importKeys.some((k) => obj.hasOwnProperty(k)) };
      }
  
      return { type: undefined, valid: false };
    };
    const getImportUrls = (obj, type, coursepath) => {
      if (!obj || !coursepath) return [];
  
      if (type === "object") obj = [obj];
  
      let urls = [];
  
      for (const o of obj) {
        if (typeof o === "string") {
          urls.push({ url: fixUrl(o, coursepath) });
          continue;
        }
  
        const importObj = o.import ?? o.fileUrl;
  
        if (Array.isArray(importObj)) {
          for (const url of importObj) {
            urls.push({ url: fixUrl(url, coursepath) });
          }
          continue;
        }
  
        urls.push({ url: fixUrl(importObj, coursepath) });
      }
  
      return urls;
    };
  
    const { valid, type } = typeCheck(obj, key);
  
    if (valid !== true) {
      if (typeof obj !== "object" || obj === null) return;
  
      for (const nextKey of Object.keys(obj)) {
        const nextObj = obj[nextKey];
  
        if (typeof nextObj !== "object" || nextObj === null) continue;
  
        await recursiveImport({
          obj: nextObj,
          key: nextKey,
          path: [...path, nextKey],
          files: [...files],
          coursepath,
          errors,
        });
      }
  
      return;
    }
  
    const importUrls = getImportUrls(obj, type, coursepath);
  
    const responses = await Promise.all(
      importUrls.map(async (obj) => {
        let data;
  
        if (!obj.url) return { ...obj, error: { message: "URL required", url: obj.url } };
  
        try {
          data = await fetchYAML(obj.url);
  
          // If this file has already been downloaded and the data contains 'import' or 'fileUrl'
          // ignore this and marks as possible circluar dependency
          const importKeyDetected = importKeys.some((k) => JSON.stringify(data).includes(k));
  
          if (files.some((f) => f.url === obj.url) && importKeyDetected) {
            const error = { message: "Circular dependency detected", url: obj.url, path: path.join(".") };
  
            return { ...obj, error };
          }
        } catch (e) {
          console.error("----- Setting data from file URL failed - url: '%s' path: '%s'", obj.url, path.join("."), e);
  
          const error = { message: e, url: obj.url, path: path.join(".") };
  
          return { ...obj, error };
        }
        return { ...obj, data };
      })
    );
  
    if (obj?.fileUrl) delete obj.fileUrl;
    if (obj?.import) delete obj.import;
  
    for (const res of responses) {
      if (res.error) {
        if (!obj.errors) obj.errors = [];
        obj.errors.push({ ...res.error });
        errors.push({ ...res.error });
        continue;
      }
  
      obj = merge(obj, res.data);
    }
  
    for (const nextKey of Object.keys(obj)) {
      // recurse the current object if there is fileUrl object in merged response data
      const nextObj = importKeys.includes(nextKey) ? obj : obj[nextKey];
  
      if (typeof nextObj !== "object" || nextObj === null) continue;
  
      await recursiveImport({
        obj: nextObj,
        key: nextKey,
        path: [...path, nextKey],
        files: [...files, ...importUrls],
        coursepath,
        errors,
      });
    }
  };
  
  export const importJson = async (obj, coursepath, settings = { ignoreToLowerCase: false }) => {
    const errors = [];
  
    await recursiveImport({ obj, coursepath, settings, errors });
  
    if (!settings.ignoreToLowerCase) obj = objectKeysToLowercase(obj);
  
    return { ...obj, errors };
  };
  
  export const logImportErrors = (courses) => {
    if (!Array.isArray(courses)) return;
  
    for (const course of courses) {
      if (!Array.isArray(course.errors)) continue;
  
      for (const error of course.errors) {
        console.error("Import error", error);
      }
    }
  };
  