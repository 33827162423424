import { answerHasValue } from "../exercises/answer";

/**
 * Process user choices for course.
 * Remove statusids/modules that require specific user role or
 * an answer to a question/role.
 * Replace module urls with chosen one.
 * @param {*} course
 * @param {*} courseUser
 * @param {*} userAnswers
 */
export function processCourseChoices(course, courseUser, userAnswers) {
  let courseAnswers = userAnswers[course.id];
  const courseTagSettings = course.settings?.coursetags;

  /**
   * Filter statusids in "course.progress.modules"
   */
  if (course.progress?.modules) {
    let moduleKeys = Object.keys(course.progress.modules);
    // Iterate modules
    for (let modIdx = 0; modIdx < moduleKeys.length; modIdx++) {
      let modKey = moduleKeys[modIdx];

      // Check modules requirements
      if (shouldRemoveElement(courseAnswers, course.progress.modules[modKey], courseUser, courseTagSettings)) {
        delete course.progress.modules[modKey];

        continue;
      }

      if (course.progress.modules[modKey].statusids) {
        // Iterate statusids
        for (let statIdx = course.progress.modules[modKey].statusids.length - 1; statIdx >= 0; statIdx--) {
          if (
            shouldRemoveElement(
              courseAnswers,
              course.progress.modules[modKey].statusids[statIdx],
              courseUser,
              courseTagSettings
            )
          ) {
            course.progress.modules[modKey].statusids.splice(statIdx, 1);
          }
        }
      }
    }
  }

  /**
   * Filter urlnames and statusids in "course.modules".
   * statusids are for backwards compatibility.
   * If module "id" fields are defined here and in "course.progress.modules",
   * statusids will get overwritten in moduleconfig.js -> applyModuleConfig,
   * and can safely be removed from the YAML.
   */
  if (course?.modules) {
    let moduleKeys = Object.keys(course.modules);

    // Iterate modules
    for (let modIdx = 0; modIdx < moduleKeys.length; modIdx++) {
      let modKey = moduleKeys[modIdx];

      /**
       * modules defined in course main .yaml-file
       * have settings listed directly.
       */

      // Backwards compatibility
      if (course.modules[modKey].statusids) {
        // Iterate statusIds
        for (let statIdx = course.modules[modKey].statusids.length - 1; statIdx >= 0; statIdx--) {
          if (
            shouldRemoveElement(courseAnswers, course.modules[modKey].statusids[statIdx], courseUser, courseTagSettings)
          ) {
            course.modules[modKey].statusids.splice(statIdx, 1);
          }
        }
      }

      // Check urlnames
      if (course.modules[modKey].urlnames) {
        for (let urlIdx = 0; urlIdx < course.modules[modKey].urlnames.length; urlIdx++) {
          const shouldRemove = shouldRemoveElement(
            courseAnswers,
            course.modules[modKey].urlnames[urlIdx],
            courseUser,
            courseTagSettings
          );

          if (!shouldRemove) {
            // Make this the active url
            course.modules[modKey].urlname = course.modules[modKey].urlnames[urlIdx].urlname;
          }
        }
      }

      /**
       * Modules defined in a separate .yaml file have a content key with submodules.
       */
      if (course.modules[modKey].content) {
        // Iterate submodules
        let submoduleKeys = Object.keys(course.modules[modKey].content);
        for (let submodIdx = 0; submodIdx < submoduleKeys.length; submodIdx++) {
          let subKey = submoduleKeys[submodIdx];

          // Backwards compatibility
          if (course.modules[modKey].content[subKey].statusids) {
            // Iterate statusIds
            for (let statIdx = course.modules[modKey].content[subKey].statusids.length - 1; statIdx >= 0; statIdx--) {
              const shouldRemove = shouldRemoveElement(
                courseAnswers,
                course.modules[modKey].content[subKey].statusids[statIdx],
                courseUser,
                courseTagSettings
              );

              if (shouldRemove && Array.isArray(course.modules[modKey].content[subKey].statusids)) {;
                course.modules[modKey].content[subKey].statusids.splice(statIdx, 1);
              }
            }
          }

          // Check urlnames
          if (course.modules[modKey].content[subKey].urlnames) {
            for (let urlIdx = 0; urlIdx < course.modules[modKey].content[subKey].urlnames.length; urlIdx++) {
              const shouldRemove = shouldRemoveElement(
                courseAnswers,
                course.modules[modKey].content[subKey].urlnames[urlIdx],
                courseUser,
                courseTagSettings
              );

              if (!shouldRemove) {
                // Make this the active url
                course.modules[modKey].content[subKey].urlname =
                  course.modules[modKey].content[subKey].urlnames[urlIdx].urlname;
              }
            }
          }
        }
      }
    }
  }

  /**
   * course.chapters
   */
  if (course?.chapters) {
    // Iterate chapters
    for (let chIdx = course.chapters.length - 1; chIdx >= 0; chIdx--) {
      if (shouldRemoveElement(courseAnswers, course.chapters[chIdx], courseUser, courseTagSettings)) {
        //course.chapters.splice(chIdx, 1);
        course.chapters[chIdx].skipNavigation = true;
      }
    }
  }

  course.selectionsLoaded = true;

  return course;
}

/**
 * Check if user meets requirements to keep this element
 * @param {*} courseAnswers
 * @param {*} statusObj
 * @param {*} courseUser
 * @param {*} courseTagSettings
 * @returns
 */
export function shouldRemoveElement(courseAnswers, statusObj, courseUser, courseTagSettings = []) {
  const roles = courseUser?.tags ? Object.keys(courseUser.tags) : [];

  /**
   * TODO: Is progress disabled with course tag
   * requires modules and progress to be mapped from original course object, not to modify the original
   *
   */
  // const componentDisabledForRole = courseTagSettings?.some((tag) => {
  //   if (!roles.some((role) => role === tag.id)) {
  //     return false;
  //   }

  //   if (tag.progress?.disabled) return true;
  //   if (tag.components?.disabled?.ids?.some((obj) => obj.id === statusObj.id)) return true;

  //   return false;
  // });

  // if (componentDisabledForRole) {
  //   return true;
  // }

  if (!statusObj?.requirements) return false;

  for (let i = 0; i < statusObj.requirements.length; i++) {
    const req = statusObj.requirements[i];

    /**
     * Is this statusId only for users with a role?
     */
    if (req.role) {
      if (roles.length === 0) return true;

      const noRole = roles.every((role) => role !== req.role);

      return noRole;
    }

    /**
     * Is this statusId only for users with a
     * specific answer to a question?
     */
    if (req.answer) {
      if (!courseAnswers) return true;

      if (!answerHasValue(courseAnswers[req.answer.id]?.data, req.answer.path, req.answer.value)) {
        // User doesn't have the answer we're looking for, remove this id from status.
        return true;
      }
    }
  }

  return false;
}
