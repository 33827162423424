import React, { useCallback, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../util/firebase";
import Checkbox from "../common/checkbox";
import { useTranslation } from "react-i18next";

import "./cookie-preferences.scss";

export const CookiePreferencesForm = () => {
  const firebase = useContext(FirebaseContext);
  const [statisticsCheckbox, setStatisticsCheckbox] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();

  const activeAnalytics = (allowAll) => {
    if (statisticsCheckbox || allowAll) firebase.activateGoogleAnalytics();
    if (!statisticsCheckbox && !allowAll) firebase.disableGoogleAnalytics();

    const settings = {
      googleAnalytics: statisticsCheckbox || allowAll ? true : false,
      timestamp: Date.now(),
    };

    window.localStorage.setItem("edukamu-cookie-settings", JSON.stringify(settings));

    setShowForm(false);
  };

  const onStatisticsChange = (e) => {
    const checked = e.target.checked;
    setStatisticsCheckbox(checked);
  };

  const openForm = () => setShowForm(true);

  const getSettingsFromLocalStorage = useCallback(() => {
    let savedSettings = window.localStorage.getItem("edukamu-cookie-settings");

    if (!savedSettings) return;

    return JSON.parse(savedSettings);
  }, []);

  useEffect(() => {
    if (!showForm) return;

    const savedSettings = getSettingsFromLocalStorage();

    if (!savedSettings) return;

    if (savedSettings.googleAnalytics === true) setStatisticsCheckbox(true);
  }, [getSettingsFromLocalStorage, showForm]);

  useEffect(() => {
    const savedSettings = getSettingsFromLocalStorage();

    if (!savedSettings) return setShowForm(true);

    if (savedSettings.googleAnalytics === true) firebase.activateGoogleAnalytics();

    setShowForm(false);

  }, [firebase, getSettingsFromLocalStorage]);

  useEffect(() => {
    window.addEventListener("cookie-form-show", openForm);

    return () => window.removeEventListener("cookie-form-show", openForm);
  }, []);

  return (
    <div className={`edukamu-cookie-preferences${showForm ? "" : "--hide"}`}>
      <h3 className="edukamu-cookie-preferences__title">{t("cookies.title")}</h3>
      <p className="edukamu-cookie-preferences__body">{t("cookies.textPortion1")}</p>
      {t("cookies.textPortion2") !== "cookies.textPortion2" && (
        <p className="edukamu-cookie-preferences__body">{t("cookies.textPortion2")}</p>
      )}
      <div className="edukamu-cookie-preferences__checkboxes">
        <Checkbox label={t("cookies.necessary")} checked={true} disabled />
        <Checkbox onChange={onStatisticsChange} label={t("cookies.statistics")} checked={statisticsCheckbox} />
      </div>
      <button className="edukamu-chapter-link__button--small" onClick={() => activeAnalytics(true)}>
        {t("cookies.allowAll")}
      </button>
      <button className="edukamu-chapter-link__button--small-secondary" onClick={() => activeAnalytics()}>
        {t("cookies.allowSelection")}
      </button>
    </div>
  );
};
