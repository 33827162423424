import React from "react";
import { UAParser } from "ua-parser-js";
import { withTranslation } from "react-i18next";

import "./browser-check.scss";

class BrowserCheck extends React.Component {
  constructor(props) {
    super(props);

    this.parser = new UAParser();
    this.browser = this.parser.getBrowser();
    this.unsupported = [
      {
        name: "Edge",
        ranges: [
          // Edge is silly and uses the same browser name for desktop and mobile,
          // but the versions are vastly different.
          //
          // Latest mobile major version is 46
          {
            start: 0,
            end: 45,
          },
          // Desktop major versions before 87 not supported
          {
            start: 47,
            end: 86,
          },
        ],
      },
    ];
  }

  render() {
    if (!this.isSupported()) {
      return this.renderWarning();
    }

    return null;
  }

  renderWarning = () => {
    const t = this.props.t;
    console.error(t("browser.unsupported"));

    return (
      <div className="browser-warning-panel">
        <p className="browser-warning-text">{t("browser.unsupported")}</p>
      </div>
    );
  };

  isSupported = () => {
    if (!this.browser || !this.browser.name || !this.browser.major) {
      return true;
    }

    for (let b of this.unsupported) {
      if (b.name === this.browser.name) {
        let ver = parseInt(this.browser.major);
        for (let range of b.ranges) {
          if (ver >= range.start && ver <= range.end) {
            console.error(`Browser not supported: ${this.browser.name} ${this.browser.version}`);
            return false;
          }
        }
      }
    }
    return true;
  };
}

export default withTranslation()(BrowserCheck);
