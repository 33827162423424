import React from "react";

const CourseContext = React.createContext({});
const ModuleContext = React.createContext({});
const ProgressContext = React.createContext({});

const withModuleContext = (Component) => (props) => {
  const moduleContext = React.useContext(ModuleContext);

  return <Component {...props} moduleContext={moduleContext} />;
};

const withProgressContext = (Component) => (props) => {
  const progressContext = React.useContext(ProgressContext);

  return <Component {...props} progressContext={progressContext} />;
};

export { CourseContext, ModuleContext, withModuleContext, ProgressContext, withProgressContext };
