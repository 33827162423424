import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import Firebase, { FirebaseContext } from "./util/firebase";

import "aos/dist/aos.css";

import "@fortawesome/fontawesome-pro/css/all.css";
//import "@fortawesome/fontawesome-pro/js/all.js";

import App from "./App";
import * as serviceWorker from "./serviceWorker";


ReactDOM.render(
  <BrowserRouter>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </BrowserRouter>,
  document.getElementById("root"),
  () => {
    // In case hidden by SSR
    document.getElementById("root").style.display = "unset";
  }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://create-react-app.dev/docs/making-a-progressive-web-app/
serviceWorker.unregister();

/*
Commented out on 19/11/2021 to revert a bug, see issue #352.
https://gitlab.com/kamkhub/web/edukamu-react/-/issues/352

If visual jumps caused by content loading becomes an issue again,
consider implementing a more proper solution.
e.g. css rules to make components take the same amount of space
on the page before and after content load where possible.

// This makes page reloading smoother when on a page
// with lazy loaded content, which will increase
// page height when loaded, causing "jumps".
window.onbeforeunload = function (event) {

  // Ignore fix for "edukamu-download" component download links
  //
  if (event.target && event.target.activeElement && event.target.activeElement.nodeName.toLowerCase() === "a" && event.target.activeElement.download !== ""){
    // Do nothing
  }
  else{
    document.body.hidden = true; // don't show scrolling
    window.scrollTo(0, 0);
  }
};
*/
