export const convertToArray = (obj, settings = {}) => {
  if (Array.isArray(obj)) return obj;

  if (typeof obj !== "object" || obj === null) {
    console.warn(`Warning: Trying to conver type '${typeof obj} into array. Expecting an object.'`);
    return [];
  }

  return Object.keys(obj).map((k) => {
    const r = { ...obj[k] };

    if (settings.includeKeyAsId) {
      r.id = k;
    }

    return r;
  });
};
