import querystring from "querystring";

export const parseRequireUrl = (url) => {
  if (typeof url === "object") {
    return url.default;
  }

  return url;
};

/**
 * Find child property from object
 * @param {*} p path (string or array of path values)
 * @param {*} o object to search from
 * @param {*} defaultValue (optional) return value
 * @returns {*} the property if found. Otherwise defaultValue if defined or null.
 */
export const getProperty_OLD = (p, o, defaultValue) => {
  let result;

  /**
   * This function doesn't seem to work if we try to find some item that has value of false. Returns null instead of false.
   */

  if (p && o) {
    if (typeof p === "string") {
      p = p.split(".");
    }
    result = p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
  }

  // Fixed defaultValue (previously didn't work if "defaultValue" was set to: false)
  return result ? result : typeof defaultValue !== "undefined" ? defaultValue : null;
};

/**
 * Find child property from object
 * @param {string} path period delimited path to the property (e.g. "foo.bar.baz")
 * @param {*} obj the object to search from
 * @param {*} defaultValue (optional) return value
 * @returns {*} the property if found. Otherwise defaultValue if defined or null.
 */
export const getProperty = (path, obj, defaultValue) => {
  if (path && obj) {
    let tmp = path.split(".");
    for (var i = 0; i < tmp.length; i++) {
      if (typeof obj[tmp[i]] !== "undefined") {
        obj = obj[tmp[i]];
      } else {
        obj = undefined;
        break;
      }
    }
  } else {
    obj = undefined;
  }

  if (typeof obj === "undefined") {
    obj = typeof defaultValue !== "undefined" ? defaultValue : null;
  }
  return obj;
};

/**
 *
 * @param {*} p
 * @param {*} o
 * @param {*} value
 * @returns {*}
 */
export const setProperty = (p, o, value) => {
  p = p.split(".");
  let i;

  for (i = 0; i < p.length - 1; i++) {
    // We need to create object for this path, if current path does not exist yet
    //
    if (typeof o[p[i]] === "undefined") {
      o[p[i]] = {};
    }
    o = o[p[i]];
  }

  return (o[p[i]] = value);
};

/**
 * Convert all object keys to lowercase
 * @param {*} obj
 * @returns {*}
 */
export const objectKeysToLowercase = (obj) => {
  if (!obj) {
    return obj;
  }

  var json = JSON.stringify(obj);

  json = json.replace(/"([\w]+)":/g, ($0, $1) => {
    return '"' + $1.toLowerCase() + '":';
  });

  var result;
  try {
    result = JSON.parse(json);
  } catch (e) {
    return obj;
  }
  return result;
};

/**
 *
 * @param {*} str
 * @param {*} coursePath
 * @returns {string} The fixed url
 */
export const fixUrl = (str, coursePath) => {
  // Skip items that have proper url
  //
  if (!str || str === "" || !coursePath || coursePath === "") {
    return str;
  }
  let apu = String(str);
  str = apu.trim();
  if (str.indexOf("http") === 0 || str.indexOf("https") === 0
  ) {
    return str;
  }

  // Fix course path if it ends with slash (e.g. "https://dev-edukamu-test.web.app/")
  //
  if (coursePath.lastIndexOf("/") === coursePath.length - 1) {
    coursePath = coursePath.substr(0, coursePath.length - 1);
  }

  // Trim slash from url if there is one (e.g. "/graphics/edukamu.png")
  //
  if (str.indexOf("/") === 0) {
    str = str.substr(1);
  }

  // Add pathname infront of url
  //
  str = coursePath + "/" + str;

  return str;
};

/**
 *
 * @param {*} obj
 * @param {*} coursePath
 */
export const fixUrlsFromObj = (obj, coursePath) => {
  if (obj !== null) {
    Object.keys(obj).forEach((key) => {
      if (key === "url") {

        // Check if urls are defined in array??
        //
        if (Array.isArray(obj[key])) {
          for (var i = 0; i < obj[key].length; i++) {
            obj[key][i] = fixUrl(obj[key][i], coursePath);
          }
        } else {
          // url is defined as single string
          //
          if (typeof obj[key] === "string") {
            obj[key] = fixUrl(obj[key], coursePath);
          }
        }
      }

      if (typeof obj[key] === "object") {
        fixUrlsFromObj(obj[key], coursePath);
      }
    });
  }

  return obj;
};

/**
 *
 * @param {*} obj
 * @param {*} coursePath
 * @returns {*}
 */
export const fixUrlsFromText = (obj, coursePath) => {
  let json = JSON.stringify(obj);

  /**
   * Fix urls from component parameters
   *
   * Example:
   *  content: url('image.jpg') --> https://dev-edukamu-test.web.app/image.jpg
   */
  json = json.replace(/"(url)":"((\\"|[^"])*)"/g, (match, first, second) => {

    second = fixUrl(second, coursePath);

    return '"url":"' + second + '"';
  });

  /**
   * Fix urls from CSS contents.
   *
   * Example:
   *  content: url('image.jpg') --> https://dev-edukamu-test.web.app/image.jpg
   */
  json = json.replace(/"(content)":"(("url\('|[^"])*'\))"/g, (wholematch, first, second) => {
    let match = second.match(/\('([^']*)'\)/);
    if (match) {
      return '"content":"url(' + fixUrl(match[1], coursePath) + ')"';
    } else {
      return wholematch;
    }
  });

  let result;
  try {
    result = JSON.parse(json);
  } catch (e) {
    return obj;
  }

  return result;
};

/**
 *
 * Merge two objects
 *
 * @param {*} target
 * @param {*} sources
 * @returns {*}
 */
export const mergeObjects = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (typeof target === "object" && typeof source === "object") {
    for (const key in source) {
      if (typeof source[key] === "object") {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeObjects(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeObjects(target, ...sources);
};

/**
 * returns selector element when it is found from observed element
 * @param {*} selector - query selector
 * @param {*} observedElement - DOM element
 * @returns
 */
export const waitForElement = (selector, observedElement) => {
  return new Promise((resolve) => {
    if (!selector || !observedElement) return null;

    let element = observedElement.querySelector(selector);

    if (element) {
      resolve(element);
    }

    new MutationObserver((__, observer) => {
      Array.from(observedElement.querySelectorAll(selector)).forEach((element) => {
        resolve(element);

        observer.disconnect();
      });
    }).observe(observedElement, {
      childList: true,
      subtree: true,
    });
  });
};

/**
 * Return Promise that waits for 'millis' milliseconds before resolving.
 * @param {number} millis
 * @returns {Promise<void>}
 */
export const sleep = (millis) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, millis);
  });
};

/**
 * Convert Date to dd/mm/yyyy string with 0-padded numbers.
 * @param {Date} date - JS Date object
 * @returns {string}
 */
export const dateToPaddedString = (date) => {
  let day = `${date.getDate()}`;
  if (day.length < 2) day = "0" + day;

  let month = `${date.getMonth() + 1}`; // month is 0-indexed in js...
  if (month.length < 2) month = "0" + month;

  let year = `${date.getFullYear()}`;
  while (year.length < 4) year = "0" + year;

  return `${day}/${month}/${year}`;
};
/**
 * Convert milliseconds (timestamp) to H:M:S:MS string with 0-padded numbers.
 * @param {number} timestamp
 * @returns {string}
 */
export const millisecondsToPaddedString = (ms) => {
  //const milliseconds = Math.floor(timestamp % 1000);
  // const seconds = Math.floor((timestamp / 1000) % 60);
  // const minutes = Math.floor((timestamp / 1000 / 60) % 60);
  // const hours = Math.floor((timestamp / 1000 / 3600) % 24);
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const seconds = Math.floor(minutesms / 1000);

  const humanized = [
    days.toString(),
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    seconds.toString().padStart(2, "0"),
    //milliseconds.toString().padStart(3, "0"),
  ].join(":");

  return humanized;
};

export const handleSearchQuery = async () => {
  const parsed = querystring.parse(window.location.search.slice(1));

  if (!parsed) return;
  let collapse;
  const exQuery = (id) => `[id='${id}'], [data-content-id='${id}']`;

  if (parsed.collapse) {
    collapse = document.getElementById(parsed.collapse);
  } else if (parsed.question) {
    // Walk up from hidden question to find collapse parent
    const hiddenExercise = await waitForElement(exQuery(parsed.question), document.body);
    if (hiddenExercise) {
      let parent = hiddenExercise.parentElement;
      while (parent) {
        if (parent.classList.contains("edukamu-collapse")) {
          collapse = parent;
          break;
        }
        parent = parent.parentElement;
      }
    }
  }

  if (collapse) {
    const collapseButton = await waitForElement(".edukamu-collapse-button", collapse);
    // click collapse button to open it
    if (collapseButton) collapseButton.click();

    if (!collapseButton || !parsed.question) {
      collapse.scrollIntoView();
      window.scrollBy({ left: 0, top: -80, behavior: "smooth" });
      return;
    }

    // Wait for hidden exercise to be replaced with normal one
    while (true) {
      await sleep(1000);
      const exercise = await waitForElement(exQuery(parsed.question), collapse);
      if (exercise.parentElement?.style?.display === "none") {
        continue;
      }

      if (exercise) {
        exercise.scrollIntoView();
        window.scrollBy({ left: 0, top: -80, behavior: "smooth" });
        const stageElement = await waitForElement(".edukamu-stages", exercise);
        stageElement.classList.add("highlight");
      }

      break;
    }
  } else if (parsed.question) {
    // Question not inside a collapse
    const exercise = document.querySelector(exQuery(parsed.question));
    exercise.scrollIntoView();
    window.scrollBy({ left: 0, top: -80, behavior: "smooth" });
    const stageElement = await waitForElement(".edukamu-stages", exercise);
    stageElement.classList.add("highlight");
  }
};

export const RANGE_TYPES = {
  NOT_STARTED: "not-started",
  ENDED: "ended",
  ACTIVE: "active",
};

/**
 * Check if current time (or given date) is in range of start and end date
 *
 * @param {string} start - for example "2022-02-02T08:57:00+02:00"
 * @param {string} end - for example "2022-02-02T09:57:00+02:00"
 * @param {string} [now=Date.now()]
 * @returns {boolean|string} Is in range
 */
export const isDateInRange = (start, end, now = Date.now(), returnType = "boolean") => {

  if (!start || now < Date.parse(start)) return returnType === "boolean" ? false : RANGE_TYPES.NOT_STARTED;
  if (!end && now > Date.parse(start)) return returnType === "boolean" ? true : RANGE_TYPES.ACTIVE;
  if (now > Date.parse(start) && now < Date.parse(end)) return returnType === "boolean" ? true : RANGE_TYPES.ACTIVE;

  return returnType === "boolean" ? false : RANGE_TYPES.ENDED;
};

export const isMillisecondsInRange = (start, end, now = Date.now(), returnType = "boolean") => {

  if (!start || now < start) return returnType === "boolean" ? false : RANGE_TYPES.NOT_STARTED;
  if (!end && now > start) return returnType === "boolean" ? true : RANGE_TYPES.ACTIVE;
  if (now > start && now < end) return returnType === "boolean" ? true : RANGE_TYPES.ACTIVE;

  return returnType === "boolean" ? false : RANGE_TYPES.ENDED;
};

export const isCourseAvailable = (tagSettings, userdata) => {
  if (!Array.isArray(tagSettings)) {
    return {
      available: true,
      types: [],
    };
  }
  // Users may have old availability tags and/or separate validity entries. Check both.
  // Note: settings from yaml are converted to lowercase
  const withDate = tagSettings.filter((t) => t.courseavailable?.start);
  const rangeTypes = withDate.map((t) =>
    isDateInRange(t.courseavailable.start, t.courseavailable.end, undefined, "rangeType")
  );

  for (var t in userdata?.tagsvalid) {
    const tagid = t;
    // Can user have orphaned availability settings? Check just in case.
    if (Object.keys(userdata.tags).includes(tagid)) {
      rangeTypes.push(isMillisecondsInRange(userdata.tagsvalid[t].courseAvailable.start, userdata.tagsvalid[t].courseAvailable.end, undefined, "rangeType"));
    }
  }

  if (rangeTypes.length <= 0) {
    // NOTE: Array.every will always return true if array has length of 0!
    return {
      available: true,
      types: [],
    };
  }
  const allDisabled = rangeTypes.every((t) => {
    if (t !== RANGE_TYPES.ACTIVE) {
      return true;
    }

    return false;
  });

  return {
    available: !allDisabled,
    types: rangeTypes,
  };
};

/**
 * 
 * @param {object, string} object - whatever object you want to check
 * @returns {null} - if object is null
 */
export function nullChecker(object) {
  if (object === null || object === undefined) {
    return null
  }
  else {
    if (typeof object === "object") {
      if (object.constructor === Object) {
        if (Object.keys(object).length === 0) {
          return null
        }
        // object is not null
        else {
          return object
        }
      }
      else if (object.constructor === Array) {
        if (object.length === 0) {
          return null
        }
        else if (object.length === 1 && object[0] === null) {
          return null
        }
        // object is not null
        else {
          return object
        }
      }
    }
    else if (typeof object === "string") {
      if (object.length === 0) {
        return null
      }
      else if (object === "null" || object === "Null" || object === "undefined" || object === "Undefined") {
        return null
      }
      else if (object === "Invalid data") {
        return null
      }
      // object is not null
      else {
        return object
      }
    }
    else {
      return object
    }
  }
}

/**
 * 
 * @param {stringLength} int - length of string to generate
 * @returns {string} - returns randomly generated string
 */
export function generateRandomID(stringLength) {
  // Declare all characters
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  // Pick characers randomly
  let str = '';
  for (let i = 0; i < stringLength; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return str;
};