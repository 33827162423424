import React from "react";

import { parseRequireUrl } from "../util/util";
import { withTranslation } from "react-i18next";

/**
 * Boundary for catching component errors.
 * Can show an error message if a wrapped component fails to load.
 *
 * Usage:
 * <ErrorBoundary>
 *   <YourComponentHere />
 * </ErrorBoundary>
 *
 * @property {boolean} showError - Should error message be shown if a component fails?
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    console.error(errorInfo);

    // TODO: log to backend?
  }

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      if (this.props.showError) {
        return (
          <div className="row justify-content-center align-content-center text-center">
            <div className="col-auto justify-content-center text-center">
              <img
                alt="error"
                src={parseRequireUrl(require("../assets/img/error-boundary.svg"))}
                style={{ width: "80px", maxWidth: "80px" }}
              />
              <p style={{ maxWidth: "160px" }}>{t("errorLoading")}</p>
            </div>
          </div>
        );
      }

      return null;
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
