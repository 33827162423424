/**
 * https://github.com/rfoel/react-font-loader/blob/master/src/index.js
 */

import { useEffect } from "react";
import { arrayOf, number, oneOfType, shape, string } from "prop-types";

/**
 *
 * @param {*} fonts
 */
const createElement = (fonts) => {
  const families = fonts
    .reduce((accumulator, font) => {
      const family = font.name.replace(" ", "+");
      const weight = ""; //(font.weight && `:${font.weight.join(',')}`) || '';
      return [...accumulator, `${family}${weight}`];
    }, [])
    .join("|");

  if (!families) {
    return null;
  }

  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = `https://fonts.googleapis.com/css?family=${families}`;

  return link;
};

/**
 *
 * @param {*} param0
 */
const FontLoader = ({ fonts }) => {
  useEffect(() => {
    const element = createElement(fonts);
    if (element) {
      document.head.appendChild(element);
      return () => document.head.removeChild(element);
    }
    return () => {};
  }, [fonts]);

  return null;
};

FontLoader.propTypes = {
  fonts: arrayOf(
    shape({
      name: string.isRequired,
      weights: arrayOf(oneOfType([string, number])),
    })
  ).isRequired,
};

export default FontLoader;
