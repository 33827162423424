import React from "react";
import { Route, Redirect } from "react-router-dom";

import CheckPasswordRoute from "./checkpasswordroute";

export default class PasswordRoute extends React.Component {
  render() {
    const { appProps } = this.props;
    let rest = {};
    for (const key of Object.keys(this.props)) {
      if (!["appProps", "children"].includes(key)) {
        rest[key] = this.props[key];
      }
    }

    return (
      <CheckPasswordRoute
        appProps={appProps}
        course={this.props.course}
        courseName={this.props.courseName}
        coursePassword={this.props.coursePassword}
        successRoute={successRoute(this.props.children, rest)}
        failRoute={failRoute(this.props.children, appProps, rest)}
      />
    );
  }
}

function failRoute(children, appProps, rest) {
  if (appProps.isAuthenticatedPW) {
    return successRoute(children, rest);
  }

  return (
    <Route
      {...rest}
      render={(props) => <Redirect to={`/loginpw?redirect=${props.location.pathname}${props.location.search}`} />}
    />
  );
}

function successRoute(children, rest) {
  return <Route {...rest} render={() => children} />;
}
