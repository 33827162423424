import React from "react";
import "./action-error.scss";

function urlparse(scrurl) {
    var url = scrurl.split('//');
    if (url[0] === "http:" || url[0] === "https:") {
        var protocol = url[0] + "//";
        var host = url[1].split('/')[0];
        return {
            protocol: protocol,
            host: host
        };
    }
    return {
        protocol: false,
        host: false
    };
}

function isRedirect(urlObj,params) {
    const hostname = urlObj.host;
    const protocol = urlObj.protocol;
    
    if (!hostname || !protocol) {
        return false;
    }
    var redirectUrl = "https://dev-edukamu.firebaseapp.com/__/auth/action?";
    var notOk = false;

    if (!params.includes("apiKey")) {
        notOk = true;
    }
    if (!params.includes("mode")) {
        notOk = true;
    }
    if (!params.includes("oobCode")) {
        notOk = true;
    }

    if (notOk) {
        return false; 
    }

    switch (hostname) {
        case "127.0.0.1":
        case "localhost":
        case "dev.edukamu.fi":
        case "dev2.edukamu.fi":
        case "staging.edukamu.fi":
            redirectUrl = "https://dev-edukamu.firebaseapp.com/__/auth/action?";
            break;
        case "test.edukamu.fi":
        case "test2.edukamu.fi":
            redirectUrl = "https://dev-edukamu.firebaseapp.com/__/auth/action?";
            break;
        case "stage.edukamu.fi":
        case "stage2.edukamu.fi":
            redirectUrl = "https://edukamu-92142.firebaseapp.com/__/auth/action?";
            break;
        default:
            redirectUrl = "https://edukamu-92142.firebaseapp.com/__/auth/action?";
            break;
    }
    window.location.replace(redirectUrl + params);
    return true;
}

export const ActionRedirect = () => {
    const urlObj = urlparse(window.location.href);
    const params = window.location.search;
    if (!isRedirect(urlObj,params)) {
        return (
            <div className="action-error-panel">
                <p className="action-error-text">Error: action call format.</p>
            </div>
        );
    }
    return true;
};


