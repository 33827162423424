import React, { useContext } from "react";
import { CourseContext } from "./context";
import { QuickSettingsContext } from "../../util/quicksettings/quicksettings";
import { setProperty } from "../../util/util";
import { withFirebase, onAuthStateChanged } from "../firebase";
import { processCourseChoices } from "./selections";
import { applyModuleConfig } from "./moduleconfig";
import { withCourseUserContext } from "../../hooks/useCourseUser";
import { aiEduCourses } from "../../util/aieducourses"

const withCourse = (Component) => {
  class WithCourse extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        isLoading: true,
        userAnswers: {},
      };
    }

    componentDidMount() {
      onAuthStateChanged(this.props.firebase.auth ,authUser => {
        this.setState({ authUser: authUser });

        if (!authUser) {
          this.setState({ isLoading: false });
        } else {
          this.props.firebase.watchAnswers(this.watchAnswersCb);
        }
      });
    }

    componentWillUnmount() {
      this.props.firebase.stopWatchingAnswers(this.watchAnswersCb);
    }

    watchAnswersCb = (answers) => {
      this.setState({ isLoading: false, userAnswers: answers ?? {} });
    };

    /*********************
    **** AI Edu feature ** 
    **********************
    *(only executed if current course is an AI Edu course)*/
    // Format course data:
    // > Assigns lessons to exercises and exercise data
    // > Assign chapters to lessons
    formatCourseData(courseProps) {

      const categorizedChapters = {};
      const exercises = {};
      var lessonNameAndID = {};
      //var chapterNameAndID = {};
      //var exerciseNameAndID = {};
      var numOfExercises = 1;
  
      if (courseProps) {
        // Assign exercises to chapters
        for (const mod of Object.keys(courseProps?.modules ?? {})) {
          const module = courseProps.modules[mod];
          //const urlPrefix = module["urlname"].split("/")[0];
  
          //addExercises(module, exercises,numOfExercises);
          let ids = module.statusids ?? [];
          if (ids.length > 0) {
            exercises[module.name] = [];
          }
          for (let statusObj of ids) {
            //let url = statusObj.chapter?.urlname ?? obj.urlname;
            //url += `?question=${encodeURIComponent(statusObj.id)}`;
  
            exercises[module.name].push({
              id: statusObj.id,
              maxpoints: statusObj["maxpoints"] ? statusObj["maxpoints"] : 0,
              name: statusObj["name"] ?? `${"exercise"} ${numOfExercises}`,
              chapterID: module["statusid"],
              chapterName: module["name"],
              urlName: `${module["urlname"]}?question=${statusObj["id"]}`,
            });
            numOfExercises++;
          }
  
          for (const con of Object.keys(module.content ?? {})) {
            const content = module.content[con];
            //addExercises(content, exercises,numOfExercises);
            let ids = content.statusids ?? [];
            if (ids.length > 0) {
              exercises[content.name] = [];
            }
            for (let statusObj of ids) {
              //let url = statusObj.chapter?.urlname ?? obj.urlname;
              //url += `?question=${encodeURIComponent(statusObj.id)}`;
  
              exercises[content.name].push({
                id: statusObj.id,
                maxpoints: statusObj["maxpoints"] ? statusObj["maxpoints"] : 0,
                name: statusObj["name"] ?? `${"exercise"} ${numOfExercises}`,
                chapterID: content["statusid"],
                chapterName: content["name"],
                urlName: `${content["urlname"]}?question=${statusObj["id"]}`,
              });
              numOfExercises++;
            }
            
  
            if (!categorizedChapters[module["name"]]) categorizedChapters[module["name"]] = [];
  
            categorizedChapters[module["name"]].push({
              id: content["statusid"],
              name: content["name"],
              urlName: content["urlname"],
              exercises: exercises[content["name"]] ? exercises[content["name"]] : null,
            });
          }
  
          lessonNameAndID[module["name"]] = module["id"];
          lessonNameAndID[module["name"]] = module["id"];
        }
      }
      return {categorizedChapters, exercises, numOfExercises, lessonNameAndID}
    }

    render() {
      const { courseUser = {}, ...rest } = this.props;

      return (
        <QuickSettingsContext.Consumer>
          {({ defaults, options, setQuickSettingsValue, clearQuickSettings }) => {
            return (
              <CourseContext.Consumer>
                {(value) => {
                  if (!this.state.isLoading && courseUser.isLoading === false) {
                    value = processCourseChoices(value, courseUser?.current, this.state.userAnswers);
                    value = applyModuleConfig(value);
                  }

                  // Apply YAML overrides if needed from Quick Setting options
                  //
                  if (options && typeof options === "object" && Object.keys(options).length > 0) {
                    // Apply user overrides from LocalStorage settings
                    //
                    if (options.quicksettings !== false) {
                      for (let key in options) {
                        setProperty(key, value, options[key]);
                      }
                    } else {
                      for (let key in options) {
                        // Do not apply overrides if they are set to false
                        //
                        if (key === "quicksettings") {
                          setProperty(key, value, options[key]);
                        } else {
                          if (typeof value.originalsettings[key] !== "undefined") {
                            setProperty(key, value, value.originalsettings[key]); // Instead override we use original course yaml value
                          }
                        }
                      }
                    }
                  }

                  /*********************
                  **** AI Edu feature ** 
                  **********************
                  *(only enabled if current course is an AI Edu course)*/
                  // Format course data for metrics and statistics
                  if (Object.keys(aiEduCourses).includes(value.id)) {
                    value.isCourseAiEduCourse = true
                  }
                  else {
                    value.isCourseAiEduCourse = false
                  }
                  // AI Edu courses added to the "props.course" as a reference for "what courses are AI Edu courses"
                  value.aiEduCourses = aiEduCourses

                  return (
                    <Component
                      {...rest}
                      course={value}
                      settings={value ? value.settings : null}
                      originalsettings={value.originalsettings}
                      savedsettings={{ ...options, ...courseUser }}
                    />
                  );
                }}
              </CourseContext.Consumer>
            );
          }}
        </QuickSettingsContext.Consumer>
      );
    }
  }

  return withFirebase(withCourseUserContext(WithCourse));
};

export const withCourseContext = (Component) => {
  return withCourseUserContext((props) => {
    let course = useContext(CourseContext);
    const { options } = useContext(QuickSettingsContext);

    // Apply YAML overrides if needed from Quick Setting options
    //
    if (options && typeof options === "object" && Object.keys(options).length > 0) {
      // Apply user overrides from LocalStorage settings
      //
      if (options.quicksettings !== false) {
        for (let key in options) {
          setProperty(key, course, options[key]);
        }
      } else {
        for (let key in options) {
          // Do not apply overrides if they are set to false
          //
          if (key === "quicksettings") {
            setProperty(key, course, options[key]);
          } else {
            if (typeof course.originalsettings[key] !== "undefined") {
              setProperty(key, course, course.originalsettings[key]); // Instead override we use original course yaml value
            }
          }
        }
      }
    }
    return (
     
      <Component
        {...props}
        course={course}
        settings={course ? course.settings : null}
        originalsettings={course.originalsettings}
        savedsettings={{ ...options, ...props.courseUser }}
      />
    );
  });
};

export default withCourse;
