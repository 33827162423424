import React from "react";
import { MatlabContext } from "./context";
import { withFirebase, onAuthStateChanged} from "../firebase";
import withCourse from "../course/course";
import { getProperty } from "../util";

/**
 * HOC for MATLAB config returned from backend. 
 */
const withMatlab = (Component) => {
  class WithMatlab extends React.Component {
    constructor(props) {
      super(props);

      /*
       * loading:
       * - true/false
       * matlabEnabled:
       * - false: ei kirjautunut tai ei käytössä kurssilla (settings.matlab.enabled)
       * - true: kirjautunut ja käytössä
       * token:
       * - null: ei tiedossa
       * - string: kirjautunut ja token olemassa
       */
      this.state = {
        loading: true,
        matlabEnabled: false,
        token: null,
      };
    }

    componentDidMount = () => {
      onAuthStateChanged(this.props.firebase.auth ,authUser => {
        if (authUser) {
          // Logged in
          this.setState({ loading: true });
          this.checkMatlab();
        } else {
          // Not logged in
          this.reset();
        }
      });
    };

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.course !== this.props.course) {
        this.setState({ loading: true, matlabEnabled: false, token: null });
        this.checkMatlab();
      }
    }

    reset = () => {
      this.setState({ loading: false, matlabEnabled: false, token: null });
    };

    checkMatlab = () => {
      const courseId = getProperty("course.id", this.props);
      if (!courseId) {
        //console.error("WithMatlab no course id");
        this.reset();
        return;
      }

      const enableMatlab = getProperty("settings.matlab.enabled", this.props.course) === true;
      if (!enableMatlab) {
        //console.error("WithMatlab no settings.matlab.enabled");
        this.reset();
        return;
      }

      this.setState({ matlabEnabled: true });

      this.props.firebase
        .reserveMatlabContainer(courseId)
        .then((val) => {
          this.setState({
            loading: false,
            token: val.token,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    };

    render() {
      let matlabContext = {
        loading: this.state.loading,
        matlabEnabled: this.state.matlabEnabled,
        token: this.state.token,
      };

      return (
        <MatlabContext.Provider value={matlabContext}>
          <Component {...this.props} matlabContext={matlabContext} />
        </MatlabContext.Provider>
      );
    }
  }
  return withFirebase(withCourse(WithMatlab));
};
export default withMatlab;
