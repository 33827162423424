import React from "react";

import { coursePasswords } from "../../config";

class CheckPasswordRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      loading: true,
    };
  }

  componentDidMount() {
    // Don't check for access in development environment
    /*
        if (process && process.env && process.env.NODE_ENV === "development") {
            this.setState({ loading: false, success: true });
            return;
        }
        */

    if (this.props.coursePassword === "none") {
      // Ignore password check..
      this.setState({ success: true, loading: false }, () => {
        this.props.appProps.userHasAuthenticatedPW(true);
      });
      return;
    }

    var password = getPassword(this);

    if (!password) {
      this.setState({ loading: false });
      return;
    }

    // Custom password processing per course
    //
    if (this.props.coursePassword) {
      var coursePassword = this.props.coursePassword;

      if (coursePassword.toLowerCase() === password.toLowerCase()) {
        this.setState({ success: true, loading: false }, () => {
          this.props.appProps.userHasAuthenticatedPW(true);
        });
      } else {
        this.setState({ loading: false });
      }
    } else {
      // General password
      //
      if (coursePasswords.includes(password)) {
        this.setState({ success: true, loading: false }, () => {
          this.props.appProps.userHasAuthenticatedPW(true);
        });
      } else {
        this.setState({ loading: false });
      }
    }
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    if (this.state.success) {
      return this.props.successRoute;
    }

    return this.props.failRoute;
  }
}

function getPassword(scope) {
  var password;

  // Custom password processing per course
  //
  if (scope.props.coursePassword && scope.props.courseName) {
    password = localStorage.getItem(scope.props.courseName + "-password");
  } else {
    password = localStorage.getItem("edukamu-course-password");
  }

  return password;
}

export default CheckPasswordRoute;
