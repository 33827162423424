import { getProperty } from "../util";

/**
 * Check if user answer has a specific value at path.
 *
 * Example:
 *   answerHasValue({ foo: { bar: "baz" } }, "foo.bar", "baz") -> true
 *
 * @param {object} answerData - User answer
 * @param {string} path - Path to wanted value
 * @param {object} value - Value to compare against
 * @returns {boolean}
 */
export const answerHasValue = (answerData, path, value) => {
  let wantedProp = getProperty(path, answerData, null);
  // Don't use !wantedProp here, will return false if value is 0.
  if (wantedProp === null) return false;

  if (typeof wantedProp == "object") {
    // Could be array or object with keys.
    // Don't care about objects, should use
    // the path prop to drill down to single value for those.
    let isArray = !!wantedProp.length;
    if (isArray) {
      if (wantedProp.includes(value)) {
        return true;
      }
    }
  } else {
    // Single value
    if (wantedProp === value) {
      return true;
    }
  }
};
