/**
 * Copy settings from "course.progress.modules" to "course.modules".
 * This lets us avoid duplicating all the ids, etc. in the course YAML.
 *
 * "course.modules" includes additional info about course structure
 * and is used in React for showing users module progress, etc. easier.
 *
 * "course.progress.modules" holds all ids and other progress settings
 * and is used for actual completion checking in firebase.
 *
 * @param {*} course
 */
export function applyModuleConfig(course) {
  if (!course?.modules || !course.progress?.modules) return course;

  const moduleKeys = Object.keys(course.modules);
  const progressModuleKeys = Object.keys(course.progress.modules);
  const chapterKeys = Object.keys(course.chapters);

  const keysToCopy = ["passlimit"];
  const arraysToCopy = ["statusids"];

  // Iterate "course.modules"
  for (let modIdx = 0; modIdx < moduleKeys.length; modIdx++) {
    const modKey = moduleKeys[modIdx];

    /**
     * Modules defined in a separate .yaml file have a content key with submodules.
     */
    if (course.modules[modKey].content) {
      // Iterate submodules
      const submoduleKeys = Object.keys(course.modules[modKey].content);
      for (let submodIdx = 0; submodIdx < submoduleKeys.length; submodIdx++) {
        const subKey = submoduleKeys[submodIdx];
        let subFound = false;

        // Iterate course.progress.modules,
        // find corresponding entry by id.
        if (course.modules[modKey].content[subKey].id) {
          for (let progressModIdx = 0; progressModIdx < progressModuleKeys.length; progressModIdx++) {
            const progressModKey = progressModuleKeys[progressModIdx];

            if (course.progress.modules[progressModKey].id === course.modules[modKey].content[subKey].id) {
              for (const k of keysToCopy) {
                let progressVal = course.progress.modules[progressModKey][k];
                if (progressVal !== undefined) {
                  course.modules[modKey].content[subKey][k] = progressVal;
                }
              }
              for (const k of arraysToCopy) {
                if (!subFound) {
                  // Empty existing array before first push.
                  // Don't empty on consecutive if module is split into multiple keys under progress.
                  course.modules[modKey].content[subKey][k] = [];
                }
                let progressVal = course.progress.modules[progressModKey][k];
                if (progressVal !== undefined) {
                  course.modules[modKey].content[subKey][k].push(...progressVal);
                }
              }
              subFound = true;
            }
          }
        }

        // Fill out chapters by id under statusids
        if (course.modules[modKey].content[subKey].statusids) {
          course.modules[modKey].content[subKey].statusids = course.modules[modKey].content[subKey].statusids.map(
            (statusObj) => {
              if (typeof statusObj.chapter === "string") {
                for (let i = 0; i < chapterKeys.length; i++) {
                  if (course.chapters[chapterKeys[i]].id === statusObj.chapter) {
                    statusObj.chapter = course.chapters[chapterKeys[i]];
                    break;
                  }
                }
              }
              return statusObj;
            }
          );
        }
      }
    }

    /**
     * modules defined in course main .yaml-file
     * have settings listed directly.
     */
    let found = false;

    // Iterate course.progress.modules,
    // find corresponding entry by id.
    if (course.modules[modKey].id) {
      for (let progressModIdx = 0; progressModIdx < progressModuleKeys.length; progressModIdx++) {
        const progressModKey = progressModuleKeys[progressModIdx];
        if (course.progress.modules[progressModKey].id === course.modules[modKey].id) {
          for (const k of keysToCopy) {
            let progressVal = course.progress.modules[progressModKey][k];
            if (progressVal !== undefined) {
              course.modules[modKey][k] = progressVal;
            }
          }
          for (const k of arraysToCopy) {
            if (!found) {
              // Empty existing array before first push.
              // Don't empty on consecutive if module is split into multiple keys under progress.
              course.modules[modKey][k] = [];
            }
            let progressVal = course.progress.modules[progressModKey][k];
            if (progressVal !== undefined) {
              course.modules[modKey][k].push(...progressVal);
            }
          }
          found = true;
        }
      }
    }

    // Fill out chapters by id under statusids
    if (course.modules[modKey].statusids) {
      course.modules[modKey].statusids = course.modules[modKey].statusids.map((statusObj) => {
        if (typeof statusObj.chapter === "string") {
          for (let i = 0; i < chapterKeys.length; i++) {
            if (course.chapters[chapterKeys[i]].id === statusObj.chapter) {
              statusObj.chapter = course.chapters[chapterKeys[i]];
              break;
            }
          }
        }
        return statusObj;
      });
    }
  }
  return course;
}
