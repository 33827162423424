import React from "react";
import { JupyterContext } from "./context";
import { withFirebase, onAuthStateChanged } from "../firebase";
import withCourse from "../course/course";
import { getProperty } from "../util";

const withJupyter = (Component) => {
  class WithJupyter extends React.Component {
    constructor(props) {
      super(props);

      /*
       * loading:
       * - true/false
       * jupyterEnabled:
       * - false: ei kirjautunut tai ei käytössä kurssilla (settings.jupyterNotebook.enabled)
       * - true: kirjautunut ja käytössä
       * jupyterPort:
       * - -1: ei tiedossa
       * - >0: kirjautunut ja portti olemassa
       */
      this.state = {
        loading: true,
        jupyterEnabled: false,
        jupyterPort: -1,
        jupyterToken: "",
      };
    }

    componentDidMount = () => {
      onAuthStateChanged(this.props.firebase.auth ,authUser => {
        if (authUser) {
          // Logged in
          this.setState({ loading: true });
          this.checkJupyter();
        } else {
          // Not logged in
          this.setState({ loading: false, jupyterEnabled: false, jupyterPort: -1, jupyterToken: "" });
        }
      });
    };

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.course !== this.props.course) {
        this.setState({ loading: true, jupyterEnabled: false, jupyterPort: -1, jupyterToken: "" });
        this.checkJupyter();
      }
    }

    checkJupyter = () => {
      const courseId = getProperty("course.id", this.props);
      if (!courseId) {
        this.setState({ loading: false, jupyterEnabled: false, jupyterPort: -1, jupyterToken: "" });
        return;
      }

      const enableJupyter = getProperty("settings.jupyternotebook.enabled", this.props.course) === true;
      if (!enableJupyter) {
        this.setState({ loading: false, jupyterEnabled: false, jupyterPort: -1, jupyterToken: "" });
        return;
      }

      this.setState({ jupyterEnabled: true });
      this.props.firebase
        .reserveJupyterPort(courseId)
        .then((val) => {
          this.setState({
            loading: false,
            jupyterPort: val.port,
            jupyterToken: val.token,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    };

    render() {
      let jupyterContext = {
        loading: this.state.loading,
        jupyterEnabled: this.state.jupyterEnabled,
        jupyterPort: this.state.jupyterPort,
        jupyterToken: this.state.jupyterToken,
      };

      return (
        <JupyterContext.Provider value={jupyterContext}>
          <Component {...this.props} jupyterContext={jupyterContext} />
        </JupyterContext.Provider>
      );
    }
  }
  return withFirebase(withCourse(WithJupyter));
};
export default withJupyter;
