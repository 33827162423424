import React from "react";
import { GeneralSettingsContext } from "./context";

const withGeneralSettings = (Component) => {
  class WithGeneralSettings extends React.Component {
    render() {
      return (
        <GeneralSettingsContext.Consumer>
          {(value) => {
            return <Component {...this.props} generalsettings={value} />;
          }}
        </GeneralSettingsContext.Consumer>
      );
    }
  }
  return WithGeneralSettings;
};
export default withGeneralSettings;
